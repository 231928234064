import { find, findAll, on, removeClass } from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";
import {deactivateScrollLock} from "@elements/scroll-lock";

const targetTopThreshold = 210;
const isMobile = () => matchMedia('(max-width: 992px)').matches;

export function init () {
    const anchors = findAll('.js-anchor-nav__item');
    const navbarAnchorLinks = findAll('.js-anchor-nav__navbar-link');
    const hasPimcoreAreaWrappers = findAll('.pimcore_area_content').length > 0; // needed for stylelab to work -> there are no pimcore-area wrapper classes there

    let currentHighlightedAnchor = null;

    on('scroll', () => {
        let highlightedAnchor = null;
        anchors.forEach(anchor => {
            const sibling = hasPimcoreAreaWrappers ? anchor.parentElement.nextElementSibling : anchor.nextElementSibling;
            if (anchor.getBoundingClientRect().top < targetTopThreshold && sibling && sibling.getBoundingClientRect().bottom > 0) {
                highlightedAnchor = anchor;
            }
        });

        if(currentHighlightedAnchor !== highlightedAnchor) {
            currentHighlightedAnchor = highlightedAnchor;
            navbarAnchorLinks.forEach(navbarAnchor => { navbarAnchor.classList.remove('anchor-nav__item--highlighted'); });

            if(highlightedAnchor) {
                const highlightedAnchorId = highlightedAnchor.getAttribute('id');
                const navbarAnchorToHighlight = find('[data-anchor-nav-navbar-link="'+highlightedAnchorId+'"]');

                navbarAnchorToHighlight.classList.add('anchor-nav__item--highlighted');
            }
        }
    }, window);

    // mobile - for links in the burger, close the burger first and then programmatically scroll to the target
    onFind('.js-anchor-nav__navbar-link', (navbarAnchor) => {
        on('click', () => {
            if(isMobile()) {
                // close the burger
                closeBurgerMenu();
                deactivateScrollLock();

                const targetId = navbarAnchor.getAttribute('data-anchor-nav-navbar-link') || navbarAnchor.getAttribute('href').replace('#', '');
                const target = find('#'+targetId);

                console.log("target", target)
                if(target) {
                    target.scrollIntoView({behavior: "smooth", block: "start"});
                }
            }
        }, navbarAnchor);
    });

    const backToTopLinks = findAll('.js-anchor-nav__back-to-top');
    backToTopLinks.forEach(backToTopLink => {
        on('click', () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }, backToTopLink);
    });

    function closeBurgerMenu() {
        const megaNavContent = findAll('.js-mega-nav');
        const megaNavToggle = findAll('.js-mega-nav__toggle');

        megaNavContent.forEach((_) => removeClass('is-open', _));
        megaNavToggle.forEach((_) => removeClass('is-open', _));
    }
}